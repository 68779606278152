<template>
	<div class="events">
		<div v-for="event in eventsArrya" :key="event" class="event-item"
		data-aos="fade-up"
		data-aos-duration="1000"
		>
			<div class="event-start">
				<div><span class="event-start-date">{{event.date}}</span><span class="event-start-time">/{{event.time}}</span></div>
			</div>
			<div class="event-title">
				<h3>{{event.title}}</h3>
			</div>
			<div class="event-descr">
				<p>{{event.descr}}</p>
			</div>
			<div class="btn-wrap" 
			data-aos="fade-up"
			data-aos-delay="200"
			data-aos-duration="1000"
			><router-link :to="event.buttonLink" class="btn" >{{event.buttonTitle}}</router-link></div>
		</div>
	</div>
</template>
<style scoped lang="scss">
  @import "../assets/scss/components/events.scss";
</style>

<script>
export default {
	props: {
		count: Number,
	},
	data(){
		return {
			eventsArrya: [],
			events:[
				/*{
					title: 'День відкритих дверей в садовому центрі',
					descr: 'Roman furniture was based heavily on Greek furniture',
					date: 18.04,
					time: '10:00',
					buttonTitle: 'ПОДІЯ У FACEBOOK',
					buttonLink: 'https://my-prototype.net/new-main'
				},
				{
					title: 'День відкритих дверей в садовому центрі',
					descr: 'Roman furniture was based heavily on Greek furniture',
					date: 18.04,
					time: '10:00',
					buttonTitle: 'ПОДІЯ У FACEBOOK',
					buttonLink: 'https://my-prototype.net/new-main'
				},
				{
					title: 'День відкритих дверей в садовому центрі',
					descr: 'Roman furniture was based heavily on Greek furniture',
					date: 18.04,
					time: '10:00',
					buttonTitle: 'ПОДІЯ У FACEBOOK',
					buttonLink: 'https://my-prototype.net/new-main'
				},
				{
					title: 'День відкритих дверей в садовому центрі',
					descr: 'Roman furniture was based heavily on Greek furniture',
					date: 18.04,
					time: '10:00',
					buttonTitle: 'ПОДІЯ У FACEBOOK',
					buttonLink: 'https://my-prototype.net/new-main'
				},*/
			]
		}
	},
	mounted () {
		this.sliceEvents()
	},
	methods: {
		sliceEvents(){
			if(this.count !== null) {
				this.eventsArrya = this.events.slice(0, this.count)
			}
			else {
				this.eventsArrya = this.events
			}
			return this.eventsArrya
		}
	}
}
</script>