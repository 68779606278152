<template>
	<div class="content"> 
		<section>
			<div class="container">
				<h2 class="sub-title">У НАС БАГАТО ЦІКАВОГО</h2>
				<h1 class="title">Всі події у квітковому господарстві Lemberg Flowers</h1>
				<div class="line"></div>
			</div>
		</section>
		<section class="events-section">
			<div class="container">
				<v-events></v-events>
			</div>
		</section>
	<section>
			<v-contacts/>
		</section>
	</div>
</template>

<style scoped lang="scss">
	@import "../assets/scss/components/event-page.scss";
</style>

<script>
import Events from '@/components/Events.vue'
import Contacts from '@/components/Contacts.vue'

export default {
	components: {
		'v-events':Events,
		'v-contacts': Contacts
	},
}
</script>
